th {
  color: #656364;
  font-weight: 500;
}
th,
.metric-table td {
  font-size: 14px;
  text-align: center;
}

.card {
  border: none;
}

.card-header {
  background-color: #fff;
  width: auto;
}

.card-font {
  font-size: 14px;
}

.cancel {
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: 1px solid #243b86;
  background-color: #fff;
  font-weight: 500;
  color: #243b86;
}

.update {
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  background-color: #656364;
  color: #fff;
  margin-left: 10px;
}

.card-body {
  word-break: break-word;
  padding-bottom: 10px;
}

.card-row {
  margin-left: 0;
}

.metrix-main-div {
  padding: 0px;
  margin-bottom: 12px;
}

.editModal {
  cursor: pointer;
}

.rs-input-group {
  border: 1px solid #d1dcff;
  border-radius: 8px;
  height: 39px;
}

.MuiSvgIcon-root {
  background-color: #d1dcff;
  border-radius: 8px;
}

.time-display {
  align-content: center;
  font-weight: 500;
  align-content: center;
  white-space: nowrap;
}

.inputText-dd .rs-picker-default .rs-picker-toggle.rs-btn {
  border: 1px solid #d1dcff;
}

.accordion-summary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.view-details-text {
  margin-right: 8px;
}

.title-container {
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
