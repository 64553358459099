.PatientGoal-part {
  .PatientTopBar {
    border-bottom: solid 1px #bcbcbc;
    padding-bottom: 10px;
    .btn.addgoal {
      background-color: #133696;
      color: #fff;
      padding: 8px 10px;
      border: none;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 500;
      span {
        font-size: 10px;
      }
    }
    .coreInput {
      height: 34px;
    }
  }
  .patientGoal-collapsible {
    .rs-panel-header,
    .rs-panel-body {
      padding: 20px 0;
    }
    .rs-panel + .rs-panel:before {
      left: 0;
      right: 0;
    }
    .rs-panel-header {
      .rs-icon {
        width: 20px;
        height: 20px;
      }
    }
    .PanelHeadertitle {
      font-size: 16px;
      span {
        font-weight: 700;

        margin-left: 10px;
        &.high {
          color: #33a429;
        }
        &.low {
          color: #d41b1b;
        }
        &.middle {
          color: #ffb800;
        }
      }
    }
    .patientGoal-card {
      border-radius: 6px;
      background: #fff;
      border: dashed 1px #e8e8e8;
      margin: 0;
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #666;

      .title {
        font-size: 14px;
        color: #333;
      }

      .material-icons-outlined {
        color: #666;
        font-size: 14px;
        cursor: pointer;
      }
      .patientGoal-subtext {
        font-size: 12px;
      }
      .goalMsg-text {
        text-align: right;
        margin-left: 50px;
        .errorMsg {
          font-size: 10px;
          color: #d41b1b;
          span {
            font-size: 10px;
            color: #d41b1b;
            padding-right: 2px;
          }
        }
      }
    }
  }
  .goalfilterBtn {
    .filterBtn.btnParentDiv {
      padding: 6px 9px;
    }
  }
  .rs-panel-body {
    border-top: 1px solid #e5e5ea;
  }
}

.chart-box {
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;

  .chart-box-Detail {
    // position: absolute;
    // top: 5%;
    // right: 3%;
    border: solid 1px #e0e0e0;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    color: #666;
    height: 100%;
    width: 150px;
    margin: 15px;

    .adherence-text {
      strong {
        font-size: 22px;
        color: #d41b1b;
      }
    }
  }
}
.goalLogDatail {
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  padding: 15px 20px;
  .filterBtn {
    padding: 9px 10px;
  }
  .title {
    font-weight: 600;
    font-size: 20px;
  }
}
.goalLogDatail-history {
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  padding: 15px 20px;
  .title {
    font-weight: 600;
    font-size: 20px;
  }
  .goal-history-box {
    border-radius: 8px;
    border: solid 1px #ede9f4;
    min-width: 300px;
    .history-time {
      background-color: #efeded;
      text-align: center;
      border-radius: 8px;
      padding: 10px;
      color: #333;
      font-weight: 500;
      line-height: 12px;
      span {
        font-size: 8px;
      }
    }
    .history-data {
      padding: 10px;
      font-size: 12px;
    }
  }
}
.patientGoal-card {
  width: 48%;
}
@media screen and (max-width: 980px) {
  .patientGoal-body {
    flex-wrap: wrap;
  }
  .patientGoal-card {
    width: 100%;
  }
}

.custom-width {
  width: 120px;
  span {
    text-align: left;
    margin-right: 10px;
  }
}
