.offcanvas.offcanvas-end.callLog-Part {
  width: 86%;
}
.bodyActive {
  .offcanvas.offcanvas-end.callLog-Part {
    width: 95%;
  }
}
.profile-call-log-btn button.call-log-btn {
  background-color: #133696;
  color: #fff;
  padding: 6px 10px;
  border: none;
  border-radius: 6px;
  &:hover {
    color: #fff;
  }
}
.offcanvas.offcanvas-end.callLog-Part {
  background-color: #eef1f3;
  border-radius: 20px 0 0 20px;
  padding: 0px 20px 20px;
  .offcanvas-header {
    padding-bottom: 0;
  }
  .call-log-hr {
    border-bottom: solid 1px #efefef;
  }
  .head {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  a {
    color: #133696;
    font-size: 12px;
    font-weight: 500;
  }
  .material-icons-outlined {
    font-size: 14px;
  }
  .badge {
    font-size: 10px;
    color: #333;
    background-color: #f3f3f3;
    border-radius: 20px;
    font-weight: 400;
    padding: 3px 10px !important;
    &.info {
      background-color: #fbe3e3;
      color: #9f2b2b;
    }
    &.sm-badge {
      font-size: 7px;
      padding: 0px 5px !important;
    }
    &.success {
      background-color: #f2fff1;
      color: #33a429;
      font-weight: 500;
    }
    &.completed {
      color: #33a429;
      background: transparent !important;
      border: solid 1px #33a429;
    }
  }
  .progress-tiles {
    border-radius: 6px;
    border-left: solid 2px #ccc;
    padding: 10px;
    color: #333;
    &.info-box {
      background-color: #fff7f5;
      border-color: #d51b1b;
      span {
        color: #d51b1b;
        font-weight: 600;
      }
    }
  }
  .call-log-left-part {
    border-radius: 6px;
    background-color: #fff;
    padding: 20px;
    width: 370px;

    .patient-pic {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #d9d9d9;
      img.rounded {
        min-width: 70px;
        height: 70px;
      }
    }
    .patient-data {
      margin-left: 20px;
      .Pname {
        font-size: 16px;
        font-weight: 500;
        span {
          font-size: 12px;
          color: #757575;
          display: block;
        }
      }
    }
    .member-details-box {
      .member-details-card {
        background-color: #f8f8f8;
        border-radius: 6px;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        font-weight: 500;
      }
      span {
        font-size: 10px;
        color: #757575;
        font-weight: 400;
        &.material-icons-outlined {
          font-size: 14px;
          color: #333;
        }
      }
    }
    .medical-issues {
      background-color: #f1efef;
      border-radius: 6px;
      padding: 8px 10px;
      ul {
        margin: 0;
        padding: 0 20px;
        li {
          font-size: 12px;
        }
      }
    }
    .medical-history {
      .dose-medicine {
        padding: 0 2px;
        letter-spacing: 4px;
      }
      .badge {
        border-radius: 5px;
        line-height: 12px;
        max-width: 100px;
      }
      table {
        tr {
          td {
            border-bottom: dotted 1px #d9dee2;
            padding: 5px 0;
            font-size: 12px;
          }
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
    .reports-box {
      font-size: 12px;
      border: dotted 1px #d9dee2;
      padding: 5px 8px;
      border-radius: 6px;
      color: #666;

      .reports-name {
        font-weight: 600;
        color: #333;
        span {
          font-size: 10px;
          font-weight: 400;
          &.showmore-btn {
            margin-left: 5px;
            font-weight: 600;
            color: #133696;
            cursor: pointer;
          }
        }
      }
      .icon span {
        font-size: 14px;
      }
    }
    .notes-box {
      font-size: 12px;
      border-bottom: solid 1px #cfdce4;
      padding-bottom: 10px;
      margin-bottom: 10px;
      .notes-assign {
        font-size: 8px;
        color: #666;
        font-weight: 500;
        margin-top: 5px;
      }
      span {
        margin-left: 5px;
        font-weight: 600;
        color: #133696;
        cursor: pointer;
      }
    }
  }
  .performance-part {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;

    .performance-box {
      width: 50%;
      border-right: solid 1px #efefef;
      padding: 0 10px 0 0;
      .adherence-box {
        .adherence-name {
          font-weight: 600;
        }
      }
    }
    .adherence-table {
      font-size: 12px;
      text-align: left;
      th {
        background-color: #f8f8f8;
        font-weight: 500;
        padding: 5px;
        text-align: left;
      }
      td {
        padding: 5px;
        text-align: left;
        strong {
          padding: 0;
          color: #3c9e33;
        }
        span {
          font-weight: 600;
          padding: 0;
        }
      }
    }
  }
  .goal-box {
    padding-left: 10px;
    width: 50%;
    .goal-card {
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin: 0 10px;
      padding: 10px;
      font-size: 12px;
      font-weight: 600;
      min-width: 210px;
      span {
        font-weight: 400;
        display: block;
      }
    }
  }
  .call-log-part {
    border-radius: 6px;
    background-color: #fff;
    .call-log-head {
      background-color: #f5f7f8;
      border-radius: 6px 6px 0 0;
      padding: 8px 10px;
      border: solid 1px #d1d4d7;
      .icon {
        display: flex;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #fff;
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
    .rs-panel {
      overflow: initial;
    }
  }
  .accordion-part {
    border-radius: 6px;
    border: dotted 1px #e8e8e8;
    padding: 10px;
    .call-textarea {
      border-radius: 6px;
      padding: 10px;
      border: solid 1px #eeeeee;
    }
  }
  .rs-panel-group-bordered {
    border: none;
    border-radius: none;
    overflow: visible;
  }
  button.call-log-btn {
    border-radius: 6px;
    border: solid 1px #133696;
    color: #133696;
    background-color: #fff;
    padding: 10px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 20px;
  }
}

// -- progress ba
// -- vars
$bg-color: #34495e;
$default-size: 20px;
$label-font-size: $default-size / 2.5;
$label-font-size-redo: $default-size * 1;

$default-size2: 40px;
$label-font-size2: $default-size2 / 4;
$label-font-size-redo2: $default-size2 * 1;

$default-size3: 50px;
$label-font-size3: $default-size3 / 4;
$label-font-size-redo3: $default-size3 * 1;
// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}
@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }
    .left-side {
      transform: rotate($progress * 3.6deg);
    }
    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}
@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);
  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}
// -- progress ba
.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  position: relative;
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: #ecf0f1;
    font-weight: 600;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: $label-font-size-redo / 10;
    line-height: $label-font-size-redo * 0.7;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: $label-font-size-redo / 10;
    justify-content: center;
    align-items: center;
    display: flex !important;

    .smaller {
      color: #bdc3c7;
      font-size: 0.45em;
      // padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
    box-shadow: none !important;
  }
  &.pie-wrapper-2 {
    @include size($default-size2, $default-size2);
    .pie {
      @include size(100%, 100%);
      clip: rect(0, $default-size2, $default-size2, $default-size2 / 2);
      left: 0;
      position: absolute;
      top: 0;

      .half-circle {
        @include size(100%, 100%);
        border: ($default-size2 / 9) solid #3498db;
        border-radius: 50%;
        clip: rect(0, $default-size2 / 2, $default-size2, 0);
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .shadow {
      border: $default-size2 / 9 solid #bdc3c7;
    }
    &.style-2 {
      .label {
        font-size: $label-font-size2;
        .smaller {
          padding-bottom: 0;
        }
      }
    }
  }

  &.pie-wrapper-3 {
    @include size($default-size3, $default-size3);
    .pie {
      @include size(100%, 100%);
      clip: rect(0, $default-size3, $default-size3, $default-size3 / 2);
      left: 0;
      position: absolute;
      top: 0;

      .half-circle {
        @include size(100%, 100%);
        border: ($default-size3 / 9) solid #3498db;
        border-radius: 50%;
        clip: rect(0, $default-size3 / 2, $default-size3, 0);
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .shadow {
      border: $default-size3 / 9 solid #bdc3c7;
    }
    &.style-2 {
      .label {
        font-size: $label-font-size3;
        .smaller {
          padding-bottom: 0;
        }
      }
    }
  }

  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;

      .smaller {
        color: #bdc3c7;
      }
    }
  }
  &.progress-10 {
    @include draw-progress(10, #d41b1b);
    &.style-2 {
      .label,
      .smaller {
        color: #d41b1b;
      }
    }
  }

  &.progress-20 {
    @include draw-progress(20, #d41b1b);
    &.style-2 {
      .label,
      .smaller {
        color: #d41b1b;
      }
    }
  }
  &.progress-30 {
    @include draw-progress(30, #d41b1b);
    &.style-2 {
      .label,
      .smaller {
        color: #d41b1b;
      }
    }
  }
  &.progress-35 {
    @include draw-progress(35, #d41b1b);
    &.style-2 {
      .label,
      .smaller {
        color: #d41b1b;
      }
    }
  }
  &.progress-40 {
    @include draw-progress(40, #ffb800);
    &.style-2 {
      .label,
      .smaller {
        color: #ffb800;
      }
    }
  }

  &.progress-50 {
    @include draw-progress(50, #ffb800);
    &.style-2 {
      .label,
      .smaller {
        color: #ffb800;
      }
    }
  }

  &.progress-60 {
    @include draw-progress(60, #ffb800);
    &.style-2 {
      .label,
      .smaller {
        color: #ffb800;
      }
    }
  }
  &.progress-70 {
    @include draw-progress(70, #33a429);
    &.style-2 {
      .label,
      .smaller {
        color: #33a429;
      }
    }
  }
  &.progress-80 {
    @include draw-progress(80, #33a429);
    &.style-2 {
      .label,
      .smaller {
        color: #33a429;
      }
    }
  }
  &.progress-90 {
    @include draw-progress(90, #33a429);
    &.style-2 {
      .label,
      .smaller {
        color: #33a429;
      }
    }
  }
  &.progress-95 {
    @include draw-progress(95, #33a429);
    &.style-2 {
      .label,
      .smaller {
        color: #33a429;
      }
    }
  }
  &.progress-100 {
    @include draw-progress(100, #33a429);
    &.style-2 {
      .label,
      .smaller {
        color: #33a429;
      }
    }
  }
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }

  .label {
    background: transparent;
  }
}
// -- progress bar
