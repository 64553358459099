.prescription-form {
  .reusableBtn {
    border: 1px solid var(--errorColor) !important;
    color: var(--errorColor) !important;
    padding: 10px 20px;
  }
  .inputMsg {
    color: red;
  }
}
