.cardsParentDiv {
  margin-top: 26px;
  gap: 24px;
}

.card-header {
  background-color: var(--lightGrey);
  border-radius: 0px !important;
  color: var(--cardTitle);
  font-weight: var(--boldWeight);
  width: 271px;
}

.cardTag {
  border-radius: 10px 10px 10px 10px !important;
  color: var(--lightBlack);
  background-color: var(--cardBackground);
  border: unset;
}
/* .cardCss {
  height: 792px;
} */

.custom-list-group-item {
  min-height: 52px;
  list-style-type: none;
  padding: 16px;
}

.custom-list-group {
  min-width: 271px;
  color: var(--cardTitle);
  font-size: var(--normalFontMd);
  cursor: pointer;
}
.custom-list-group-item:hover {
  font-weight: var(--lightWeight);
  background-color: var(--primaryLighter);
  color: var(--themeDarkColor);
  border-radius: 10px;
}

.custom-list-group-active,
.custom-list-group-active.custom-list-group-item:hover {
  font-weight: var(--semiBoldWeight);
  background-color: var(--primaryLighter);
  border-radius: 10px;
  color: var(--themeDarkColor);
}

.custom-list-group {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1180px) {
  .cardsGap {
    flex-direction: column;
  }
  .custom-list-group {
    min-width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .custom-list-group-active {
    min-width: auto;
  }
}
