.appointment-tab {
  border-radius: 10px;
  background: rgba(217, 217, 217, 0.2);
  padding: 20px 10px;
  min-width: 300px;
  max-height: 1000px;

  overflow-y: auto;
  position: relative;
}

.doctor-list {
  /* border-radius: 10px; */
  /* background: rgba(217, 217, 217, 0.2); */
  padding: 20px 10px;
  min-width: 280px;
  max-height: 80vh;

  overflow-y: auto;
}

.appointment-tab h3 {
  color: #474d53;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  top: 0;
  position: sticky;
}

.appointment-doctor-item {
  border-radius: 5px;
  border: 0.5px solid #b2b1b1;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.appointment-doctor-item:hover,
.appointment-doctor-item.selected {
  border: 0.5px solid #243b86;
  background: #dee5fb;
}

.appointment-doctor-item h4 {
  color: #243b86;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 10px;
}

.appointment-doctor-item p {
  color: #474d53;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.appointment-doctor-item .doctorClinic {
  margin-bottom: 10px;
}

.appointment-doctor-item .doctorClinic:last-child {
  margin-bottom: 0;
}

.appointment-wrapper {
  margin-left: 24px;
}

.appointment-wrapper h3 {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.appointment-schedule-tab {
  border-top: solid 1px #eaeaea;
  padding: 10px 0 0;
}

.appointment-schedule {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 10px;
}

.appointment-slot {
  border-radius: 10px;
  border: 1px solid #dee5fb;
  background: #fff;
  margin-bottom: 10px;
}

.slot-time {
  border-radius: 10px;
  border: 1px solid #dee5fb;
  background: #dee5fb;
  min-width: 90px;
  padding: 15px;
  font-size: 10px;
}

.slot-info {
  padding: 10px;
}

.slot-tab {
  border-radius: 8px;
  border: 1px solid #cfdce4;
  background: #fff;
  font-size: 16px;
  color: #333;
  font-weight: 400;
  padding: 4px 6px;
  margin-right: 8px;
  cursor: pointer;
}

.slot-tab:hover {
  background: #dee5fb;
}

.slot-name {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.slot-name p {
  font-weight: 400;
}

.slot-actions button {
  border-radius: 6px;
  border: 0.839px solid #243b86;
  background: #fff;
  margin-top: 8px;
  padding: 4px;
  color: #243b86;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.slot-actions select {
  border-radius: 6.712px;
  border: 0.839px solid rgba(216, 224, 250, 0.84);
  color: #243b86;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.slot-badge {
  /* font-weight: var(--boldWeight); */
  border-radius: 1rem;
  /* font-size: var(--normalFontSm); */
  /* font-weight: var(--boldWeight); */
  /* line-height: 1rem; */
  padding: 0.25rem 0.625rem;
  /* background-color: var(--successLighter) !important; */
  /* color: var(--successDarker) !important; */
}
.slot-badge-closed {
  border: 1px solid gray;
  color: gray !important;
}
.slot-badge-active {
  border: 1px solid #33a429;
  color: #33a429 !important;
}
.slot-badge-overdue {
  border: 1px solid red;
  color: red !important;
}
.appointment-schedule-date {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.appointment-schedule-date span {
  font-weight: 400;
}

.appointment-schedule-date div {
  padding: 0 20px;
}

.appointment-schedule-date button {
  border-radius: 4px;
  border: 1px solid var(--Gray-400, #dcdfe3);
  background: #fff;
  padding: 8px 12px;
}
.appointment-time-slot {
  color: #333;
  font-weight: '300';
  line-height: 12px;
  font-size: 12px;
  margin: 2.5px 0;
}
.shareIcon {
  font-size: 12px !important;
}
.appointment-heading {
  font-weight: '400' !important;
  font-size: 16 !important;
}

.rs-dropdown-toggle {
  width: 100%;
}
