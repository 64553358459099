.showGoals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  gap: 10px;

  img {
    max-width: 80px;
    max-height: 70px;
  }

  .d-flex {
    gap: 10px;
  }

  .userDetailGoal {
    display: flex;

    img {
      cursor: pointer;
    }
  }
}
