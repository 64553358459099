.loader {
  border: 3px solid transparent;
  border-radius: 100%;
  border-top: 3px solid var(--themeDarkColor);
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

#loaderDiv {
  position: fixed;
  background-color: #ffffffa9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
