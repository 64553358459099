/* .calendarDiv {
  border: #dee2e6 1px solid; 
   padding: 12px;
  margin-top: 8px;
  border-radius: 6px; 
} */

.calendar-Part {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.calendar-Part .fc-header-toolbar {
  display: flex;
  flex-direction: column;
}

.calendar-Part .zoho-patient-calendar .fc-header-toolbar {
  display: flex;
  flex-direction: row;
}

.calendar-Part .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  margin-bottom: 8px;
}

.calendar-Part .zoho-patient-calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  border-bottom: none !important;
  margin-bottom: 0;
}

.calendar-Part .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  display: none;
}

.calendar-Part .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  display: flex;
  width: 100%;
  justify-content: end;
}

.calendar-Part .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) button {
  text-transform: capitalize;
}

.calendar-Part .fc--button.fc-button.fc-button-primary {
  display: none;
}

.calendar-Part .fc-header-toolbar .fc-toolbar-title {
  font-size: 16px;
  line-height: 36px;
}

.calendar-Part .fc-prev-button,
.calendar-Part .fc-next-button {
  border: none;
  color: #333;
  background: #f0f0f0;
  padding: 0;
  margin: 0;
  font-size: 10px;
  padding: 4px 6px;
  margin-right: 3px;
}

.calendar-Part table th a {
  color: #333;
  margin: 5px 0;
}

.calendar-Part .fc .fc-daygrid-day-top {
  display: flex;
  justify-content: flex-end;
  color: #333;
}

.calendar-Part .fc .fc-daygrid-day-top a {
  color: #333;
  font-weight: 400;
}

.calendar-Part table td {
  cursor: pointer;
}

.calendar-Part .zoho-patient-calendar .fc-today-button {
  display: none;
}

.calendar-Part .zoho-patient-calendar .fc-theme-standard .fc-scrollgrid,
.calendar-Part .zoho-patient-calendar .fc-theme-standard td,
.calendar-Part .zoho-patient-calendar .fc-theme-standard th {
  border: none;
  vertical-align: middle;
}

.calendar-Part .zoho-patient-calendar .fc .fc-daygrid-day-top {
  justify-content: center;
}

.calendar-Part .zoho-patient-calendar .fc .fc-col-header-cell-cushion {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 20px;
}

.calendar-Part .zoho-patient-calendar .fc .fc-daygrid-day.fc-day-today {
  background: none;
}

.calendar-Part .zoho-patient-calendar .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top a {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #133696;
  color: #fff;
  text-align: center;
}

/* .calendar-Part .zoho-patient-calendar .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: none;
} */

.meetings-list {
  max-height: 260px;
  overflow-y: scroll;
}

.meetings-list .meetings-box {
  display: flex;
  width: 100%;
  justify-content: start;
  background-color: #f4dde0;
  border: solid 1px #cbaaae;
  color: #333;
  border-radius: 3px;
  padding: 6px 5px;
  margin-bottom: 12px;
  font-size: 13px;
  justify-content: space-between;
}

.meetings-list .meetings-box span.edit-btn {
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}

.meetings-list div.zoho-list-more {
  background-color: #efebf6;
  border: solid 1px #efebf6;
  font-weight: 600;
  justify-content: center;
  display: flex;
  border-radius: 3px;
  padding: 6px 5px;
  font-size: 13px;
}

.meetings-list div span {
  font-size: 8px;
  margin-top: 4px;
  margin-right: 4px;
}

.availabel-slots button {
  background: none;
  border: solid 1px #666;
  margin-bottom: 8px;
  border-radius: 3px;
  padding: 1px 4px;
  min-width: 75px;
}

.availabel-slots button:hover {
  background-color: #133696;
  color: #fff;
  border-color: #133696;
}

.availabel-slots {
  max-height: 240px;
  overflow-y: scroll;
}

.availabel-slots .selected-timeslot {
  background-color: #133696;
  color: #fff;
  border-color: #133696;
}

.zoho-tab button.rs-btn.rs-btn-primary {
  background-color: #133696;
}

.calendar-Part .zoho-patient-calendar .fc-daygrid-day-bottom {
  display: flex;
}

.calendar-Part .zoho-patient-calendar .fc .fc-daygrid-day-bg .fc-highlight {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: var(--fc-highlight-color);
  color: #fff;
  text-align: center;
  margin: auto;
  top: 0;
  position: relative;
}

.calendar-Part .zoho-patient-calendar .fc-daygrid-day-bottom a.fc-daygrid-more-link {
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: #133696;
  display: flex;
  margin: auto;
  overflow: hidden;
  cursor: auto;
  margin-top: 4px;
}

.calendar-Part .zoho-patient-calendar .fc-popover {
  display: none;
}

.calendar-Part .zoho-calendar-box .zoho-calendar-content {
  background: #fbfbfb;
  padding: 20px;
  border: solid 1px #dee2e6;
}

/* Dashboard*/
.admin-zoho-calendar .zoho-calendar-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.admin-zoho-calendar .zoho-calendar-box .cardbody {
  width: 50%;
}

.admin-zoho-calendar .zoho-calendar-box .zoho-calendar-content {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  margin: 0 0 0 20px;
}

@media only screen and (max-width: 980px) {
  .admin-zoho-calendar .zoho-calendar-box {
    flex-direction: column;
  }

  .admin-zoho-calendar .zoho-calendar-box .cardbody {
    width: 100%;
  }

  .admin-zoho-calendar .zoho-calendar-box .zoho-calendar-content {
    min-width: 100%;
    margin: 0;
  }
}

/* Dashboard*/
