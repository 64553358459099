.loginHeading {
  font-size: var(--normalFontXl);
  line-height: 32px;
  margin: 44px 0;
  font-weight: var(--lightWeight);
  max-width: 70%;
}

.errorColor {
  color: var(--dustRed);
}

.loginFormImage {
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
}
.loginFormImage {
  overflow: hidden;
}
.loginFormImage img {
  width: 100%;
}
.flexBgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 100vh;
  background-color: var(--bodyBlankBG);
}

.cardBody {
  max-width: 560px;
  width: 85%;
  /* height: 92%; */
  height: auto;
  box-shadow: var(--cardBoxShadow);
  margin: 0 10px;
  padding: 64px 64px 72px;
  background-color: var(--whiteColour) !important;
  /* overflow: auto; */
}
.NamelogoImage {
  width: 184px;
}

.footerText {
  line-height: 20px;
  font-size: var(--normalFontMd);
}
.footerText a {
  color: var(--primaryMain);
  text-decoration: none;
}

.disabledOtpBtn {
  background-color: var(--lightGrey) !important;
  color: var(--disabledTextColor) !important;
}
.loginFormPart .alert-dismissible {
  margin-top: 0;
}
.loginFormPart .alertSpace {
  min-height: initial;
}
@media only screen and (max-width: 980px) {
  .loginFormImage {
    display: none;
  }
  .loginFormPart.flexBgDiv {
    width: 100vw;
  }
  .loginFormPart .cardCss {
    height: auto;
    padding: 25px;
  }
  .loginFormPart .loginHeading {
    margin: 25px 0;
  }
}
