.dateRangeDiv {
  padding: 24px 8px 0px;
  margin-bottom: 16px;

  .dateRange {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .dateHeading {
    font-size: var(--normalFontMd);
    margin: 0 0 12px;
  }

  .orderRadioButton {
    border: 1.5px solid #243B86;
  }

  .tags {
    border: 1px solid var(--lightGrey);
    padding: 3px 10px;
    border-radius: 35px;
    color: var(--disabledTextColor);
    font-size: var(--btnFontSmd);
    font-weight: var(--semiBoldWeight);
  }
}
