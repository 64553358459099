.editorStyle {
  margin: 24px 0 0 0;
}
.editorLabelStyle {
  font-size: var(--normalFontMd);
  margin-bottom: 4px;
}

.editorLabelStyle span {
  color: var(--dustRed);
}

.ql-container.ql-snow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: var(--inputBorderColor);
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-color: var(--inputBorderColor);
  font-size: var(--normalFontMd);
  line-height: 20px;
}

.ql-editor {
  min-height: 300px;
  max-height: 85vh;
  overflow: auto;
}
