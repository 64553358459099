.patient-details .form-group {
  margin-bottom: 20px;
}

.patient-details .address-card {
  position: relative; /* To position the edit button absolutely within this container */
  display: flex;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 30%;
}

.patient-details .address-card input {
  margin-right: 10px;
}

.patient-details .address-card label {
  flex-grow: 1;
}

.patient-details .edit-btn {
  position: absolute; /* Absolute positioning for the edit button */
  top: 10px;
  right: 10px;
  background: transparent; /* Ensure background is transparent */
  border: none; /* Remove default border */
  cursor: pointer;
}

.patient-details .new-address {
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.patient-details .address-form {
  margin-top: 10px;
}

.patient-details .save-btn {
  padding: 10px 20px;
  margin-top: 10px;
  margin-left: 25px;
  background-color: #243b86;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
}

.patient-details .common-btn {
  padding: 10px 20px;
  margin: 5px;
  background-color: #d9d9d9;
  color: #757575;
  border-radius: 8px;
}

.patient-details .common-btn.active {
  background-color: #ebeffc;
  color: #757575;
  border: 0.75px solid #243b86;
}
