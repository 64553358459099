.dashboardBody {
  display: flex;
  gap: 16px;
  width: 100%;
  cursor: pointer;
}

.dashBoardBoxTitle {
  color: var(--themeDarkColor);
}

.dashBoardCount {
  font-size: var(--normalFontBig);
  font-weight: var(--boldWeight);
  color: var(--dashBoardCountColor);
}
.dashBoardCount span {
  color: var(--lightBlack);
}
.dashboardTitle {
  font-weight: var(--semiBoldWeight);
}
@media screen and (max-width: 767px) {
  .dashboardBody {
    flex-wrap: wrap;
  }
}
