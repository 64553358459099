.linksFlexDiv {
  gap: 36px;
  flex-wrap: wrap;
}
.linksFlexDiv .activeLink,
.linksFlexDiv .inActiveLink:hover {
  text-decoration: underline;
  color: var(--themeDarkColor);
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.linksFlexDiv .inActiveLink {
  color: var(--disabledTextColor);
  cursor: pointer;
}

.linksFlexDiv {
  gap: 36px;
}
