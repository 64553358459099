.articleFormDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.articleInputDiv {
  width: 49%;
}

.publishedArticle {
  background-color: var(--lightGrey);
  color: var(--disabledTextColor) !important;
}

@media only screen and (max-width: 767px) {
  .articleInputDiv {
    /* min-width: 49%; */
    width: 100%;
    max-width: 100% !important;
  }
}
