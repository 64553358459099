.patientHeader {
  background-color: white;
  padding: 15px 24px;
}
.patient-body {
  background-color: var(--lightGrey);
  max-width: 100%;
  .align-items-center {
    .userDp {
      img {
        width: 40px;
      }
    }
  }
}

.profile-divider {
  border: 1px solid #d9dee2;
}

.social-icon {
  left: 27.44%;
  right: 26.82%;
  top: 27.78%;
  bottom: 30.22%;
  max-width: 16px;
  height: 16px;
}

.graph-card-title {
  font-weight: var(--semiBoldWeight);
  font-size: var(--normalFontxm);
  line-height: 19px;
  color: #ec6330;
  cursor: pointer;
  span {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    margin-right: 5px;
  }
  .graph-icon {
    width: 100%;
  }
}

.graph-btn-group {
  .btn.btn-light {
    &:hover {
      color: #fff;
    }
  }
}

.graph-unit {
  color: var(--disabledTextColor);
}

.profileCard {
  position: absolute;
}

.cardImage {
  width: 143px;
  height: 143px;
  border-radius: 77px;
}
.rotateImage {
  width: 17px;
  height: 16px;
}
.mailImage {
  width: 16px;
  height: 16px;
  margin-top: 4px;
}
.userAgeMaleCityText {
  margin-left: 10px;
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
}
.activeBtnPatient {
  background-color: var(--successLighter);
  color: var(--successDarker);
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  width: fit-content;
}
.linesviewActivePatient {
  margin-right: 12px;
}
.yearlyPlan {
  background-color: var(--primaryLighter);
  width: fit-content;
  color: var(--primaryDarker);
  margin-top: 8px;
}
.extendplanText {
  color: var(--whiteColour);
}
.extendplanDiv {
  display: flex;
  flex-direction: row;
}
.extendplan {
  background-color: var(--themeDarkColor);
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
  color: var(--lightBlack);
  border: 1px solid var(--inputBorderColor);
  width: fit-content;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
}
.phonenumberstyle {
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
  cursor: pointer;
  font-weight: var(--semiBold);
  color: var(--themeDarkColor);
  text-decoration: underline;
  cursor: pointer;
  text-transform: capitalize;
}

.whatsAppPhone {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-left: 12px;
}

.headerlinePatient {
  width: 350px;
  margin-left: 12px;
}
.headerlineBody {
  width: 260px;
  position: relative;
  bottom: 235px;
  left: 127px;
}
.linesViewPatient {
  margin-left: 12px;
}

.daysPatient {
  color: var(--disabledTextColor);
}

.roleButtonPatient {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
}

.assignBtnPatient {
  color: var(--lightBlack);
  border: 1px solid var(--inputBorderColor);
  width: fit-content;
}

.assign-text-box {
  img {
    max-height: 16px;
    max-width: 16px;
  }
}

.close-icon {
  img {
    max-height: 16px;
    max-width: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.dayBtn {
  background-color: azure;
}
.prescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.prescriptiontime {
  color: var(--lightBlack);
  font-weight: var(--lightWeight);
  margin-left: 4px;
}
.prescriptionconsultant {
  margin-left: 42px;
  margin-top: -19px;
  color: var(--lightBlack);
  font-weight: var(--lightWeight);
}
.priscriptionprofile {
  display: flex;
  flex-direction: row;
}

.weekBtnPatient {
  display: flex;
  flex-direction: row;
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
  background-color: var(--inputBorderColor);
  border: 1px solid var(--inputBorderColor);
  width: 325px;
  position: absolute;
  bottom: 10px;
  /* justify-content: center; */
  padding: 10px;
  left: 17px;
}
.btnStyle {
  display: 'flex';
  justify-content: 'center';
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 20px;
}

.btnStyle:hover {
  background-color: white;
}

.card:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.userName {
  margin: 10px;
  font-size: var(--normalFontxm);
  color: var(--blackColour);
}
.memberSince {
  margin-top: 14px;
}
.disableText {
  color: var(--disabledTextColor);
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
}

.monthLeft {
  color: var(--errorColor);
}

.container {
  display: flex;
  flex-direction: row;
}

.CardStyle {
  width: 300px;
  height: 221px;
  margin-left: 564px;
  position: absolute;
}
.assignCardStyle {
  width: 408px;
  height: 102px;
  margin-left: 870px;
  position: absolute;
}
.assignteamName {
  margin-left: 4px;
  color: black;
}
.assignedPatientImage {
  height: 24px;
  width: 24px;
  border-radius: 50% !important;
  object-fit: cover;
  margin-right: 5px !important;
}

.assignPatient {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--disabledTextColor);
  border: 1px solid var(--inputBorderColor);
  padding: 4px 10px;
  border-radius: 30px;
  margin: 4px;
  width: fit-content;
  color: var(--cardTitle);
  .capitalize {
    font-size: var(--normalFontSm);
  }
  .capitalize.bold {
    font-weight: var(--boldWeight);
    font-size: var(--normalFontXsm);
  }
}
.dropDownItemCustom {
  input {
    font-size: var(--normalFontMd);
  }
}

.patientlinesview {
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1;
  align-items: baseline;
}
.crossassignpatient {
  width: 18px;
  height: 18px;
  background-color: var(--placeHolderColor);
  border-radius: 20px;
  margin-left: 10px;
}
.crossAssign {
  position: relative;
  top: -4px;
  left: 5px;
  color: var(--whiteColour);
}
.AssignBtn {
  margin: 2px;
}
.BmiCard {
  width: 408px;
  height: 257px;
  position: relative;
  left: 847px;
  top: 108px;
}
.motuImage {
  width: 94px;
  height: 224px;
}
.cardBody {
  display: flex;
  flex-direction: column;
}
.lastUpdate {
  position: relative;
  bottom: 216px;
  left: 135px;
  color: var(--lightBlack);
  font-size: var(--normalFontSm);
}
.CurrentBmi {
  position: relative;
  bottom: 240px;
  left: 133px;
  margin-right: 20px;
}
.Bmiadjust {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}
.CurrentBmiWeight {
  position: relative;
  bottom: 210px;
  right: 80px;
}
.StartBmiWeight {
  position: relative;
  bottom: 210px;
  right: 55px;
}
.startbmiActive {
  background-color: var(--lightGrey);
  color: var(--lightBlack);
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  width: fit-content;
}
.CurrentShape {
  position: relative;
  bottom: 200px;
  left: 145px;
}
.CurrentHeight {
  position: relative;
  bottom: 226px;
  left: 265px;
  padding: 4px, 10px, 4px, 10px;
}

.Oval {
  position: absolute;
  left: 167px;
  top: 166px;
}
.Inch {
  position: absolute;
  left: 267px;
  top: 166px;
}
.suger {
  position: absolute;
  left: 150px;
  top: 190px;
  background-color: var(--errorLighter);
  color: var(--errorDarker);
}
.BloodPressure {
  position: absolute;
  left: 250px;
  top: 190px;
  background-color: var(--lightGrey);
  color: var(--lightBlack);
}
.sugerNumber {
  position: absolute;
  left: 160px;
  top: 220px;
}
.BloodPercentage {
  position: absolute;
  left: 250px;
  top: 220px;
}
.graphCard {
  position: relative;
  width: 840px;
  height: 973px;
  bottom: 48px;
}

.graphHelthLastUpdate {
  font-size: var(--normalFontMd);
  position: relative;
  left: 33px;
  bottom: 3px;
  color: var(--disabledTextColor);
}
.drinkingWater {
  background-color: var(--primaryLighter);
}

.syncData {
  background-color: var(--themeDarkColor);
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding: 4px 10px; */
  border-radius: 8px;
  color: var(--cardTitle);
  color: var(--lightBlack);
  border: 1px solid var(--inputBorderColor);
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 3px;
  position: relative;
  left: 400px;
  top: 4px;
  display: flex;
  flex-direction: row;
}
.syncDataText {
  color: var(--whiteColour);
}
.graphBorder {
  width: 360px;
  height: 380px;
  border: 1px solid var(--inputBorderColor);
  position: absolute;
  top: 134px;
  left: 47px;
  border-radius: 5px;
}

.Energy {
  position: relative;
  left: 28px;
  bottom: 15px;
  color: #ec6330;
}

.WaterLevel {
  position: relative;
  left: 28px;
  bottom: 15px;
  color: #57b3f4;
  font-size: var(--normalFontxm);
  font-weight: var(--semiBoldWeight);
}
.significantlyFont {
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
  position: relative;
  left: 20px;
  bottom: 10px;
}
.miliSecond {
  position: relative;
  left: 292px;
  bottom: 76px;
  font-size: var(--normalFontXx);
  font-weight: var(--boldWeight);
}
.kilogram {
  position: relative;
  bottom: 61px;
  left: 18px;
  font-size: var(--normalFontXl);
}
.kallory {
  position: relative;
  left: 328px;
  bottom: 104px;
  color: var(--disabledTextColor);
}
.kalloryMs {
  position: relative;
  left: 328px;
  bottom: 304px;
  color: var(--disabledTextColor);
}
.EnergyImg {
  position: relative;
  width: 14px;
  left: 10px;
  top: 4px;
}
.graphBorderRight {
  width: 360px;
  height: 380px;
  border: 1px solid var(--inputBorderColor);
  position: absolute;
  top: 134px;
  right: 47px;
  border-radius: 5px;
}
.graphBorderBottom {
  width: 360px;
  height: 390px;
  border: 1px solid var(--inputBorderColor);
  position: absolute;
  top: 552px;
  left: 47px;
  border-radius: 5px;
}
.graphBorderBottomRight {
  width: 360px;
  height: 390px;
  border: 1px solid var(--inputBorderColor);
  position: absolute;
  top: 552px;
  right: 47px;
  border-radius: 5px;
}
.Weighgraph {
  width: 408px;
  height: 340px;
  position: absolute;
  top: 448px;
  left: 1105px;
}
.weightGraphtext {
  font-size: var(--normalFontMd);
  font-weight: var(--middleWeight);
}
.weightDropDownAlign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addgoal {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
  color: var(--lightBlack);
  border: 1px solid var(--themeDarkColor);
  width: fit-content;
  margin-left: -3px;
  display: flex;
  flex-direction: row;
  padding-left: 14px;
  padding-right: 23px;
  cursor: pointer;
  width: max-content;
}

.addprescription {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--lightBlack);
  margin-left: 13px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding-left: 14px;
  padding-right: 23px;
  cursor: pointer;
  width: max-content;
  background-color: #ffc225;
}
.prescriptioncardbtn {
  display: flex;
  flex-direction: row;
}
.prescriptionprogress {
  height: 3px;
  width: 94%;
  margin-top: 15px;
}
.prescriptionprogressbar {
  background-color: #ffc225;
}
.prescriptioncarddetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.prescriptioncard {
  background-color: #fcedc7;
}
.border-left {
  border-left: 3px solid #ffc225;
}

.addprescriptiontext {
  color: var(--cardTitle);
  width: max-content;
  font-weight: var(--boldWeight);
}
.addgoaltext {
  color: var(--themeDarkColor);
  width: max-content;
}
.plusaddgoal {
  color: var(--themeDarkColor);
  margin-right: 7px;
  width: 18px;
  height: 17px;
}
.newgoalassigntextdiv {
  display: flex;
  flex-direction: row;
}
.newgoaltext {
  margin-top: 26px;
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
}
.newgoaltime {
  margin-top: 26px;
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
  color: var(--lightBlack);
  margin-left: 13px;
}
.healthCotchfitness {
  display: flex;
  flex-direction: row;
}
.coachImage {
  margin-top: 20px;
  height: 36px;
  width: 36px;
  border-radius: 1.1rem !important;
  margin-right: 7px;
}

.ReportImage {
  height: 36px;
  width: 36px;
  border-radius: 1.1rem !important;
  margin-right: 7px;
}

.coachtext {
  font-size: var(--normalFontMd);
  margin-top: 15px;
  font-weight: var(--boldWeight);
}

.reporttext {
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
}
.zeefitness {
  color: var(--lightBlack);
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
}
.bartext {
  position: absolute;
  right: 6px;
  color: black;
}
.divProgress {
  height: 3px;
  width: 97%;
}
.colorprogress {
  color: var(--primaryMain);
}
.capacity {
  display: flex;
  flex-direction: row;
  position: relative;
  /* bottom: -10px; */
  top: -32px;
}
.drinkWater {
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
  text-decoration: italic;
}
.ltr {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--primaryMain);
  border: 1px solid var(--primaryMain);
  width: fit-content;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 14px;
  padding-right: 23px;
}
.daily {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--primaryMain);
  border: 1px solid var(--primaryMain);
  width: fit-content;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 14px;
  padding-right: 23px;
}
.goalbtn {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
  /* color: var(--lightBlack); */
  color: white;
  border: 1px solid var(--themeDarkColor);
  width: -moz-fit-content;
  width: fit-content;
  /* margin-left: 27px; */
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  margin-bottom: 10px;
  padding-left: 11px;
  padding-right: 13px;
  background-color: var(--themeDarkColor);
}
.drinkTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--lightBlack);
  font-weight: var(--lightWeight);
}
.editPencil {
  position: absolute;
  right: 22px;
  width: 33px;
  height: 18px;
}
.bordercapacity {
  border: 1px solid var(--primaryMain);
  transform: rotate(90deg);
  width: 171px;
  position: relative;
  top: 52px;
  left: -98px;
  height: 5px;
  background-color: var(--primaryMain);
}
.newprescription {
  display: flex;
  flex-direction: row;
}
.running {
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
  text-decoration: italic;
  background-color: #d8f5ce;
}
.steps {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: #65d83c;
  border: 1px solid #65d83c;
  width: fit-content;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 14px;
  padding-right: 23px;
}
.Dailystep {
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: #65d83c;
  border: 1px solid #65d83c;
  width: fit-content;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 14px;
  padding-right: 23px;
}
.runningProgress {
  height: 3px;
  width: 92%;
  color: #65d83c;
}
.colorrunningprogress {
  color: #65d83c;
}
.goalbtnRunning {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  padding: 4px 10px;
  border-radius: 8px;
  color: var(--cardTitle);
  color: white;
  border: 1px solid #65d83c;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
  padding-left: 11px;
  padding-right: 13px;
  background-color: #65d83c;
}
.bordercapacityrunning {
  border: 1px solid #65d83c;
  transform: rotate(90deg);
  width: 173px;
  position: absolute;
  top: 247px;
  left: -80px;
  height: 5px;
  background-color: #65d83c;
}
.rightImage {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 60px;
  left: 8px;
}
.rightImagedaily {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 60px;
  left: 121px;
}
.greenRightImagesteps {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 60px;
  left: 12px;
}
.greenRightstep {
  margin-left: 15px;
}
.greenRightImagedaily {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 60px;
  left: 138px;
}
.Monthly {
  color: #65d83c;
  position: absolute;
  right: 22px;
  top: 149px;
  font-weight: var(--boldWeight);
}
.Monthlygoalstar {
  width: 13px;
  height: 12px;
  position: absolute;
  right: 199px;
  top: 153px;
}
