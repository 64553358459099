.patient-order-container {
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-width: 1px, 0px, 0px, 1px;
  border-style: solid;
  border-color: #d2d2d240;
}

.patient-order-container .order-text {
  font-size: 12px;
  color: #919191;
  font-weight: 400;
  line-height: 14.52px;
}

.patient-order-container .order-date {
  font-size: 12px;
  color: #333333;
  font-weight: 500;
  margin-right: 15px;
  line-height: 20px;
}

.patient-order-container .order-address {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
}

.order-modal {
  display: flex !important;
  justify-content: space-between !important;
  /* flex-direction: row; */
  width: 100%;
  align-items: center;
  margin-right: 50px;
}

.order-modal div p {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  line-height: 14.52px;
  margin: 5px 0 5px 0px;
}

.cardDataBorder {
  max-width: 100% !important;
}

.custom-modal {
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-modal .modal-body {
  padding: 10px;
  margin: 35px 0 0 0 !important;
}

.rs-modal-body {
  margin-top: 35px;
}

.custom-modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-modal ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-modal ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* .coreInput {
  height: 34px;
  border-radius: 0;
  padding: 0px !important;
  text-align: center;
} */

/* .table {
  width: 95% !important;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
} */

.table-suborder {
  width: 95% !important;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.table {
  margin-bottom: 0.5rem;
}

.table-heading {
  background-color: #d2d2d240 !important;
  width: max-content;
  padding: 15px;
  border-radius: 5px;
}

.w-95 {
  width: 95% !important;
}

.orderList {
  z-index: 2;
}

.status-text {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.table .badge.pending {
  background-color: var(--errorLighter) !important;
  color: var(--errorDarker) !important;
}

.fulfilled-suborder tbody tr:last-child {
  border-bottom-width: none;
  border-bottom-style: none;
  border-bottom-color: white;
}

.badge.active.in_progress,
.badge.active.unfulfilled-status,
.badge.active.cancelled {
  background-color: #d9d9d94d !important;
  color: #757575 !important;
}

.badge.active.delivered {
  background-color: #336b1f !important;
  color: #d8f5ce !important;
}

.badge.active.order_placed {
  background-color: #d8f5ce !important;
  color: #336b1f !important;
}

.vl {
  border-left: 2px solid #c8d2ed; /* Adjust the color and width as needed */
  height: 100%; /* Make sure it covers the full height */
  margin-left: 8px;
  margin-right: 2px;
  color: #fff;
}
.patient-name {
  background-color: #ecf0fb;
  font-weight: bold;
  color: #243b86;
  border-radius: 30px;
  padding: 3px 8px 3px 3px;
}

.patient-address {
  margin-left: 10px;
  width: 35%;
}

.order-summary {
  margin-bottom: 20px;
  max-width: max-content;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-bottom: 2.5vh;
}
