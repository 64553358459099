.formTitle {
  font-weight: var(--semiBoldWeight);
  margin-bottom: 24px;
  font-size: var(--normalFontxm);
  border-bottom: 1px solid var(--inputBorderColor);
}

.webEngage td {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  padding-top: 20px;
}

.webEngage span.badge {
  margin-right: 8px;
}

.webEngage-form {
  border: solid 1px #DADEE2;
  background-color: #F4F4F4;
  padding: 10px;
}

.pointer-auto {
  cursor: pointer;
}
