.inputlabel {
  text-align: left;
  display: block;
  margin-bottom: 4px;
  font-size: var(--normalFontMd);
  line-height: 20px;
  font-weight: var(--lightWeight);
  color: var(--lightBlack);
  white-space: nowrap;
  /* text-transform: capitalize; */
}

.inputParent {
  position: relative;
  display: flex;
  align-items: center;
}

.inputParent input {
  line-height: 24px;
}

.leftIcon,
.rightIcon {
  left: 10px;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
}

.leftIcon img,
.rightIcon img {
  height: 24px;
  width: 24px;
}

.rightIcon {
  left: unset;
  right: 10px;
}

.inputPaddingRight {
  padding-right: 32px !important;
}

.coreInput {
  border: 1px solid var(--inputBorderColor);
  box-shadow: unset;
  height: 34px;
  border-radius: var(--inputRadius);
  color: var(--cardTitle);
  font-size: var(--normalFontMd);
}

.coreInput:focus {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.coreInput:focus,
.coreInput:hover {
  border-color: var(--themeColor);
}

.requiredInput {
  color: var(--errorColor);
}

.textArea {
  height: unset;
}

select:invalid {
  color: #7b858f;
}

.inputShowCountryCode {
  color: var(--disabledTextColor);
}
