.order-form {
  padding: 20px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #474d53;
  font-weight: 600;
}

.tab.active {
  border-bottom: 2px solid #243b86;
  color: #243b86;
}

.tab-content {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.address-card {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.address-card input {
  margin-right: 10px;
}

.edit-btn {
  margin-left: auto;
}

.add-new-address {
  margin-top: 20px;
}

.address-form {
  margin-top: 10px;
}

.save-btn {
  margin-top: 10px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.next-btn {
  padding: 10px 20px;
  background-color: #243b86;
  color: #fff;
  border-radius: 8px;
}

.dis-next-btn {
  padding: 10px 20px;
  background: none;
  background-color: #e9ecef;
  color: #666 !important;
  border-radius: 8px;
}

.previous-btn {
  padding: 10px 20px;
  background-color: #243b86;
  color: #fff;
  border-radius: 8px;
  margin-right: 10px;
}

.save-order-btn {
  padding: 10px 20px;
  background-color: #243b86;
  color: #fff;
  border-radius: 8px;
  margin-right: 10px;
}

.img-icons {
  /* border: 1px solid; */
  border-radius: 25px;
  padding: 8px;
  margin-right: 8px;
  background-color: #d9d9d9;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapperContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tab-content {
  flex: 1;
}

.form-footer {
  margin-top: auto;
  padding: 1rem;
  text-align: center;
}
