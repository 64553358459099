.showArticleList {
  margin-bottom: 50px;

  .imageCard {
    width: 100%;
    position: relative;
    td {
      margin-bottom: 16px;
      padding: 16px;
      vertical-align: top;
    }
    .flextd {
      display: flex;
    }
  }
  .usersDivDisable {
    opacity: 0.5;
  }

  .articlePostImg {
    height: 76px;
    width: 140px;
    min-width: 140px;
    border-radius: 4px;
  }

  .postDetails {
    display: flex;
    gap: 16px;
    white-space: nowrap;
  }

  .postHeading {
    line-height: 20px;
    color: var(--themeDarkColor);
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: var(--normalFontxm);
    font-weight: var(--semiBoldWeight);
  }

  .postbody {
    line-height: 20px;
    color: var(--lightBlack);
    font-size: var(--normalFontMd);
  }

  .viewsSection {
    display: flex;
    color: var(--blackColour);
    gap: 5px;
    align-items: center;
  }

  .viewsSection img {
    height: 16px;
    width: 16px;
  }

  .aboutPost {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: var(--normalFontMd);
    color: var(--lightBlack);
    white-space: nowrap;
    align-items: baseline;
  }
}
