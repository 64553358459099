.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%); /* Initially hidden outside the screen */
  transition: transform 0.3s ease;
  z-index: 1000;
}

.drawer.open {
  transform: translateX(0); /* Slide in */
}

.closeDrawer {
  margin: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 999;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}
