.createArticleBody {
  width: 100%;
  margin: 24px 0;
}

.linkUrlInput {
  color: var(--primaryMain);
  border-color: var(--primaryMain);
  background-color: unset !important;
}

.linkUrlInputHrLine {
  margin-top: 17px;
}
