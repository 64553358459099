* {
  box-sizing: border-box;
}

.otp-input-fields {
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.otp-input-fields input {
  height: 48px;
  width: 100%;
  border-radius: var(--otpInputRadius);
  border: 1px solid var(--gray);
  text-align: center;
  outline: none;
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input[type='number'] {
  -moz-appearance: textfield;
}

.otpText {
  margin-bottom: 16px;
  font-size: var(--normalFontMd);
  line-height: 20px;
  font-weight: var(--boldWeight);
  color: var(--lightBlack);
  display: flex;
  justify-content: space-between;
}

.timerText {
  color: var(--themeColor);
  font-weight: var(--lightWeight);
}

.timerDiv {
  font-size: var(--normalFontSm);
  display: flex;
  gap: 4px;
  font-weight: var(--lightWeight);
}
.otpEditTextColor {
  color: var(--primaryMain);
  cursor: pointer;
}

.resendOtpText {
  text-align: center;
  color: var(--themeDarkColor);
  cursor: pointer;
}

.inactiveOtpBtn {
  color: var(--lightBlack);
  cursor: not-allowed;
}

.activeOtpBtn {
  color: var(--themeColor);
  cursor: pointer;
}
