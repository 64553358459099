.teamFormDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.teamFormDiv > :first-child .inputlabel {
  margin-top: 0px !important;
}

.profilePhotoStyle {
  width: 160px;
  height: 160px;
  background-color: var(--lightGrey);
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  color: var(--disabledTextColor);
  align-items: center;
  text-align: center;
  font-size: var(--normalFontMd);
  font-weight: var(--boldWeight);
}

.uploadPhotoBtn {
  background-color: unset;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  font-size: var(--normalFontMd);
  gap: 8px;
  color: var(--themeDarkColor);
  font-weight: var(--semiBoldWeight);
  max-width: 115px;
  margin: 20px auto 0;
}

.uploadPhotoBtn:hover {
  opacity: 0.8;
}

.profilePhotoStyle img {
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .teamBorderBox .articleInputDiv {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .teamBorderBox .teamAddForm {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.slot-row {
  display: flex;
  /* align-items: center; */
  /* margin: 5px 0; */
}
.slot-add {
  margin-left: 10px;
  /* border: 1px solid; */
  color: #243b86;
}

.slots-time {
  display: flex;
  gap: 8px;
}
.slots-time input {
  width: 100%;
  border: 1px solid #e3e5e7;
  border-radius: 8px;
  padding: 8px;
  background: #fff;
}
