.profileQuestion {
  padding-top: 28px;

  .boxContent {
    gap: 15px;
    margin: 10px 0;
    font-size: var(--normalFontMd);

    img {
      cursor: pointer;
      margin-left: 5px;
    }

    .keyText {
      color: var(--disabledTextColor);
      margin: 5px 0;
    }
  }

  .userDetails {
    width: 100%;
    margin-right: 5px;
    div {
      white-space: nowrap;
    }
  }

  .questionDetails {
    div {
      text-align: justify;
    }
  }

  .metaDetail {
    color: var(--disabledTextColor);
    font-size: var(--normalFontSm);
    text-transform: capitalize;
  }
}
