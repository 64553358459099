.articleViewPage {
  .card {
    width: unset;
    height: unset;
    box-shadow: unset;
    margin-top: unset;
    border: unset;
  }
  h2 {
    color: var(--cardTitle);
  }
  .logoImage {
    margin: 32px;
  }
  .articleImage {
    max-height: 432px;
  }

  .tags {
    font-size: var(--normalFontMd);
    font-weight: var(--semiBoldWeight);
    color: var(--cardTitle);
    background: var(--lightGrey);
    padding: 3px 10px;
    border-radius: 35px;
    text-transform: capitalize;
  }

  .flexDisplay {
    display: flex;
    gap: 8px;
  }

  @media only screen and (max-width: 800px) {
    .articleImage {
      max-height: 300px;
    }
  }

  .emptyView {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
