:root {
  --themeColor: #ea6f54 !important;
  --themelightColor: #fff8f7;
}

/* Side Bar Redesign */
.sidebarLogo,
.articleListSideBar.sidebar-close .sidebarLogo {
  border: none;
}
.articleListSideBar,
.side_switcher_bar::before,
.side_switcher_bar::after,
.side_switcher_bar .material-icons {
  background-color: var(--themeColor);
}
.sidebarScreen .sideBarText,
.side_switcher_bar .material-icons {
  color: var(--whiteColour);
}

.sidebarScreen a:hover,
.sidebarScreen a.activeLink {
  color: var(--greyLight);
  background-color: var(--themelightColor);
}
/* Side Bar Redesign */
.profile-dropdown-menu .profilesub_menu:hover,
.rs-dropdown-item.rs-dropdown-item-focus,
.rs-dropdown-item:focus-visible {
  color: var(--themeColor);
}
.coreInput:focus,
.coreInput:hover {
  border-color: #c3c3c3;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
select:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus,
.coreInput:focus,
.rs-btn:focus-visible,
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  background-color: #fff;
  border-color: #c3c3c3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(203 204 206 / 25%);
}
.header-right-part .form-control:focus,
.header-right-part .form-control:hover,
.multiselect-container .search-wrapper:hover {
  border-color: #C3C3C3 !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn:focus,
.rs-picker-default .rs-picker-toggle.rs-btn:hover {
  border-color: #c3c3c3;
}

/* side dropdown menu colour*/
.sideLinks-submenu > .material-icons,
.sideLinks-submenu .rs-dropdown-toggle {
  color: var(--whiteColour);
}
.sidebarScreen .sideLinks-submenu .sideBarText,
.bodyActive .sidebarScreen .sideLinks-submenu:hover .material-icons,
.sideLinks-submenu:hover > .material-icons,
.side_switcher_bar .sideLinks-submenu .material-icons,
.sidebarScreen .sideLinks-submenu:hover .material-icons {
  color: #333;
}
.bodyActive .sideLinks-submenu .sidebarScreen .sideLinks-submenu:hover button,
.sidebarScreen .sideLinks-submenu:hover button,
.bodyActive .sidebarScreen .sideLinks-submenu:hover {
  color: var(--greyLight);
  background-color: var(--themelightColor) !important;
  border-radius: 0;
}
.goalPopover.segmentlist-icon:hover .material-icons {
  color: #EA6F54;
}
