.orderTypeFilter {
    margin-bottom: 4px;
    margin-top: 10px;
  }

  .checkboxContainer {
    margin-top: -8px;
    margin-left: -10px;
  }
  
  .filterHeading {
    margin-bottom: 10px;
  }
  
  .rs-checkbox-group-inline .rs-checkbox {
    margin-right: 10px;
  }

  .manualCheckox {
    margin-right: 16px;
  }
  
  