.order-details {
  font-family: Arial, sans-serif;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.patient-name {
  background-color: #ecf0fb;
  font-weight: bold;
  color: #243b86;
  border-radius: 30px;
  padding: 3px 8px 3px 3px;
}

.patient-address {
  margin-left: 10px;
  width: 35%;
}

.view-prescription {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.medicine-table {
  width: 100%;
  border-collapse: collapse;
}

.medicine-table th,
.medicine-table td {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  text-align: center;
}

.order-details-table th {
  background-color: #d9d9d940;
}

.add-medicine {
  padding: 10px;
  background-color: #f3f5fc;
  color: #243b86;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
  text-align: start;
  font-weight: 600;
}

.order-summary {
  margin-bottom: 20px;
  max-width: 100%;
}

.order-summary > div {
  flex: 1;
  text-align: center;
}

.notes-section {
  margin-bottom: 20px;
}

.notes-section label {
  display: block;
  margin-bottom: 10px;
}

.notes-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
}

.note-container {
  display: flex;
  align-items: center;
}

.note {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #d1dcff;
  border-radius: 5px;
}

.note-time {
  margin-right: 10px;
  font-size: 12px;
  color: gray;
}

.next-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* .vl {
  border-left: 2px solid #c8d2ed;
  
} */
.vl {
  border-left: 2px solid #c8d2ed; /* Adjust the color and width as needed */
  height: 100%; /* Make sure it covers the full height */
  margin-left: 8px;
  margin-right: 2px;
  color: #fff;
}

.complete-address {
  font-weight: 500;
}

.minus-btn {
  padding: 0px 6px 0px 6px;
  background: #243b86;
  color: white;
  border-radius: 4px;
}

.add-btn {
  padding: 0px 6px 0px 6px;
  background: #757575;
  color: white;
  border-radius: 4px;
}

.medicine-quant {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 3px 0 3px;
}

.textarea-container {
  position: relative;
  width: 100%;
}

.textarea-container textarea {
  flex-grow: 1;
  width: 100%; /* Ensures the textarea takes the full width of the container */
  padding-right: 40px; /* Add some padding to the right to make space for the button */
}

.textarea-container .save-button {
  position: absolute;
  bottom: 10px; /* Adjust this value as needed */
  right: 10px; /* Adjust this value as needed */
  background: transparent;
  border: none;
  cursor: pointer;
}

.textarea-container .save-button img {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.table-responsive1 {
  overflow: visible !important; /* Allow dropdown to extend outside */
}