.notifications {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--btnRadius);
  background-color: var(--lightGrey);
}

.dropdown-content:hover {
  background: var(--lightGrey);
  border-radius: 6px;
}

.border {
  border-top: var(--inputBorderColor);
}
.userImageList {
  height: 40px;
  width: 40px;
  border-radius: 1000px;
  object-fit: cover;
}

.dropDownColor {
  position: 'absolute';
  width: '22%';
  background-color: 'white';
  right: 85;
  cursor: 'pointer';
  z-index: 10;
  border-right: '1px solid gray';
  border-left: 'px solid gray';
  /* border-bottom: '.5px solid gray'; */
  border-radius: ' 6px';
}

.notifications img {
  height: 24px;
  width: 24px;
}

.userDp img {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
}
.logOutCursor {
  margin-left: 4px;
  cursor: pointer;
}

.logoutIconImage {
  height: 24px !important;
}

.userImage {
  object-fit: cover;
}
.dropdown-content {
  display: flex;
  flex-direction: row;
}
.BoxItem {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.dropDownImageList {
  height: 20px;
  width: 21px;
  margin-top: 8px;
  margin-left: 5px;
}

.dropDownImageProfile {
  height: 20px;
  width: 21px;
  margin-top: 8px;
  margin-left: 5px;
  border-radius: 1000px;
}

.BoxContent {
  position: absolute;
  width: 88%;
  background-color: white;
  left: 51px;
  cursor: pointer;
  z-index: 10;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-color: var(--inputBorderColor);
}

.hrLine {
  margin: auto;
  background-color: #d9dee2;
}

.rbt {
  width: 350px;
}
.breadcrumbprofile {
  font-size: var(--normalFontMd);
  align-items: center;
}
.breadcrumbprofile a {
  color: var(--greyLight);
  line-height: 25px;
  display: block;
  padding-top: 3px;
}
.breadcrumbprofile img.breadcrumbpic {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 7px;
}
.addnewform-part {
  margin-top: 24px;
  color: var(--themeDarkColor);
  font-weight: var(--semiBoldWeight);
  margin-bottom: 10px;
}
.breadcrumbprofile div {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.breadcrumbprofile > div:last-child div {
  cursor: default !important;
}

.breadcrumbprofile .arrow .material-icons {
  font-size: 20px;
  color: #bfc6cc;
}
.breadcrumbIcon .material-icons-outlined,
.breadcrumbprofile .material-icons-outlined {
  color: #232c34;
  margin-right: 7px;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .breadcrumbprofile {
    margin-top: 9px;
  }
  .header-main {
    flex-direction: column-reverse;
  }
  .header-main:first-child {
    align-items: start !important;
  }
  .header-right-part {
    width: 100% !important;
    margin-bottom: 8px;
  }
}
