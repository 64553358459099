.extend-btn button {
  background: var(--themeDarkColor);
  border-radius: 6px;
  text-align: center;
  padding: 6px 15px;
  font-size: var(--normalFontSm);
  margin-right: 10px;
  color: #fff;
  border: none;
  font-weight: var(--boldWeight);
}
