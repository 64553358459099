.headerTextLite {
  color: var(--disabledTextColor);
  font-weight: var(--lightWeight);
  font-size: var(--normalFontMd);
  margin-right: 10px;
}

.headingTitleLink {
  font-weight: var(--semiBoldWeight);
}
