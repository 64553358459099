.loginHeader {
  background-color: var(--whiteColour);
  height: 84px;
  display: flex;
  align-items: center;
}

.plainLogo {
  height: 36px;
  margin-left: 24px;
}
