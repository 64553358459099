.appointmentStickyHeader.stickyHeader {
  z-index: 996;
}

.appointmentheader {
  .headbox {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
  }
  .weekCalender {
    .weekdate {
      font-size: 14px;
      font-weight: 500;
      color: #656364;
      text-align: center;
      border: solid 1px #dcdfe3;
      border-radius: 4px;
      min-width: 40px;
      line-height: 14px;
      padding: 4px 0;
      cursor: pointer;
      width: 100%;
      span {
        font-size: 8px;
        width: 100%;
      }
      &.active {
        background-color: #243b86;
        border-color: #243b86;
        color: #fff;
      }
    }
    .slider-arrow {
      cursor: pointer;
    }
  }
}
.appointmentTable {
  margin: 10px 0 0px 30px;
  height: calc(100vh - 150px);
  position: relative;
  .stickytop-table {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  table {
    width: 100vw;
  }
  th {
    padding: 10px 10px;
  }
  td {
    padding: 0px 10px;
    vertical-align: top;
    border-top: solid 1px #e2e1e2;
    width: 100%;
  }
  .schedulTime {
    min-height: 60px;
    width: 90px;
    font-size: 12px;
    border-right: solid 1px #e2e1e2;
  }
  .doctorAppointment {
    padding: 8px;
    border: solid 1px #8239bc;
    color: #333;
    font-size: 12px;
    min-height: 56px;
    position: sticky;
    width: 176px;
    top: 0;
    background-color: #fff;
    z-index: 999;
    .doctor-pic {
      img {
        width: 30px;
      }
    }
    .doctorname {
      font-size: 12px;
      font-weight: 400;
      text-overflow: ellipsis;
      width: 60%;
      overflow: hidden;
    }
    .material-icons-outlined {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #243b86;
      cursor: pointer;
    }

    .scheduletime {
      font-size: 8px;

      p {
        font-weight: 400;
      }
    }
  }
  .scheduleTile {
    // background-color: #f3f4f6;
    // border-left: solid 4px #8239bc;
    //  border-radius: 4px;
    font-size: 10px;
    padding: 0px 5px;
    // cursor: pointer;

    &.SchedulBg1 {
      border-color: #8239bc;
      border-radius: 4px;
    }
    &.SchedulBg2 {
      border-color: #ff9921;
      border-radius: 4px;
    }
    &.SchedulBg3 {
      border-color: #e423dd;
      border-radius: 4px;
    }
    &.SchedulBg4 {
      border-color: #33a429;
      border-radius: 4px;
    }
    &.SchedulBg5 {
      border-color: #e4d123;
      border-radius: 4px;
    }
    &.SchedulBg6 {
      border-color: #969eb9;
      border-radius: 4px;
    }
    &.overdueBg {
      background-color: #feeee9;
    }

    p {
      font-size: 8px;
      font-weight: 400;
    }
    .tileName {
      font-weight: 500;
      width: 96%;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      cursor: pointer;
    }
    .scheduleIcon {
      background-color: #d9d9d9;
      width: 19px;
      height: 18px;
      border-radius: 50%;
      margin-left: 2px;
      text-align: center;

      &.active {
        background-color: #243b86;
      }
      img {
        width: 14px;
      }
    }
  }
}
.statusBar {
  font-size: 8px;
  span {
    font-size: 12px;
  }
  &.overdue {
    color: #f24e1e;
  }
  &.noshow,
  &.scheduled {
    color: #33a429;
  }
}
.schedulePopover {
  width: 300px;
  padding: 0;
  // cursor: pointer;
  .schedulePopover-head {
    background-color: #f3f3f3;
    padding: 10px;
    border: solid 1px #e5e2e2;
    border-radius: 4px 4px 0 0;
    .tileName {
      font-size: 14px;
      font-weight: 600;
      color: #243b86;
      line-height: 20px;
    }
  }
  .schedulePopover-body {
    padding: 5px 15px;
    .schedule-part {
      border-bottom: solid 1px #e5e7ea;
      padding: 10px 0;
      img {
        width: 14px;
        margin-right: 5px;
      }
      p {
        font-size: 10px;
        color: #656364;
        padding: 0;
        margin: 0;
        margin-left: 24px;
      }
    }
    .time {
      font-size: 12px;
      font-weight: 600;
      color: #333;
    }
    .follow {
      color: #656364;
      border: solid 1px #333;
      border-radius: 36px;
      padding: 3px 5px;
      text-align: center;
      min-width: 80px;
      width: auto;
    }
    .popover-btn {
      font-size: 10px;
      border: solid 1px #243b86;
      border-radius: 3px;
      padding: 4px 5px;
      margin-bottom: 5px;
      min-width: 60px;
      cursor: pointer;
      &:hover {
        background-color: #e7edff;
      }
      .material-icons-outlined {
        font-size: 10px;
        margin-right: 4px;
      }
      &.disabled {
        // border-color: #c5c5c8;
        // background-color: #c5c5c8;
        border-color: #243b86;
        background-color: #243b86;
        color: #fff;
        &:hover {
          background-color: #0d2164;
        }
      }
      &.active {
        border-color: #243b86;
        background-color: #243b86;
        color: #fff;
        &:hover {
          background-color: #0d2164;
        }
      }
    }
  }
}
.appontmentModle-body {
  padding: 20px 50px;
  .slotTag {
    background-color: #dee5fb;
    color: #333333;
    padding: 9px;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    min-width: 140px;
    cursor: pointer;
    &:hover {
      background-color: #c0cbef;
    }
  }

  label {
    color: #434d56;
  }
}
.schedulButton {
  div {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &.borderBtn {
      color: #243b86;
      border: solid 1px #243b86;
      border-radius: 10px;
      &:hover {
        background-color: #e7edff;
      }
    }
  }
}
.recordPayment {
  .recordPayment-top-box {
    background-color: #f9fafe;
    border-radius: 10px;
    padding: 10px;
  }
  .tileName {
    font-size: 14px;
    font-weight: 600;
    color: #243b86;
    line-height: 20px;
  }
  .consultationTitle {
    img {
      width: 14px;
      margin-right: 5px;
    }
  }
  .popover-btn {
    font-size: 10px;
    border: solid 1px #243b86;
    border-radius: 3px;
    padding: 4px 5px;
    margin-bottom: 5px;
    min-width: 60px;
    color: #243b86;
    background: #fff;
    .material-icons-outlined {
      font-size: 10px;
      margin-right: 4px;
    }
  }
}
.modalTitle {
  font-weight: 600;
}
.doctorTiles {
  height: 1540px;
  position: absolute;
  top: 0;
  width: 177px;
  z-index: 995;
  // background-color: #f1f2f4;
  &::after {
    background-color: #fff;
    content: '';
    display: block;
    min-height: 4px;
  }
  .available-time-slots {
    position: absolute;
    z-index: 994;
    width: 177px;
    background-color: #f2efef;
    opacity: 0.4;
    overflow: hidden;
  }
  &.borderColor2 {
    .doctorAppointment {
      border-color: #ff9921;
    }
    .tileDiv {
      border-left-color: #ff992194;
    }
  }
  &.borderColor3 {
    .doctorAppointment {
      border-color: #e423dd94;
    }
    .tileDiv {
      border-left-color: #e423dd94;
    }
  }
  &.borderColor4 {
    .doctorAppointment {
      border-color: #33a42994;
    }
    .tileDiv {
      border-left-color: #33a42994;
    }
  }
  &.borderColor5 {
    .doctorAppointment {
      border-color: #e4d12394;
    }
    .tileDiv {
      border-left-color: #e4d12394;
    }
  }
  &.borderColor6 {
    .doctorAppointment {
      border-color: #969eb9;
    }
    .tileDiv {
      border-left-color: #969eb9;
    }
  }
}
.tileDiv {
  position: absolute;
  // top: calc(114px + 0px);
  // left: calc(110px + 196px);
  z-index: 995;
  width: 177px;
  background-color: #ece9e9;
  border: solid 1px #fff;
  border-left: solid 2px #8239bc;
  border-radius: 4px;
  overflow: hidden;
  // border-bottom: solid 2px #fff;
}
.appointmentheader .renewalDate {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputRadius);
  padding: 10px 8px;
  width: 66px;
}
.appointmentStickyHeader {
  .reusableBtn {
    padding: 8px 10px;
  }
}
.appointmentPopover {
  .reusableBtn {
    padding: 14px 10px;
  }
}

.clinicalDropdown {
  position: relative;
  .inputParent input {
    text-overflow: ellipsis;
    padding-right: 40px;
  }
  .rs-picker.rs-picker-input {
    position: absolute;
    top: 0;
    height: 38px;
    overflow: hidden;
    background: none;
    border: none;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
    background: none !important;
    height: 38px;
  }
  .rs-picker-toggle-placeholder {
    display: none;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
    display: none;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .appointmentTable {
    height: calc(100vh - 350px);
  }
}
