.priceRangeFilter {
    margin-bottom: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }
  
  .filterHeading {
    //font-weight: bold;
    margin-bottom: 10px;
  }

  .rangeSliderContainer {
    margin-left: 6px;
  }

  .filterRangeSubHeading {
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
  }

  .inputWrapperLabel {
    color: #757575;
    font-weight: 400;
  }
  
  .inputRow {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }
  
  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
  }
  
  .inputWrapper label {
    margin-bottom: 2px;
  }
  