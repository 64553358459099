.coreBtn,
.tabClickBtn.rs-btn.rs-btn-default,
.patientBtnNew.rs-btn.rs-btn-default {
  background-color: var(--themeDarkColor);
  margin: 18px 0;
  height: 48px;
  font-size: var(--normalFontxm);
  font-weight: var(--semiBoldWeight);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--inputRadius);
}

.btnImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.coreBtn:hover,
.tabClickBtn.rs-btn.rs-btn-default:hover {
  background-color: var(--primaryMain);
}
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
  color: var(--primaryMain);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.coreBtn.form-control:disabled,
.coreBtn:hover:disabled {
  background: none;
  background-color: #e9ecef;
  color: #666 !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #666;
  font-size: 10px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.coreBtn:hover .tooltip-text {
  visibility: visible;
}
