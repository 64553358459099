.tagsView {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding-top: 28px;
}

.tagsView .tags {
  margin-right: auto;
  border-radius: 8px;
}

.tagsViewIcons {
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.tagsViewIcons div {
  cursor: pointer;
  padding: 0 0 0 5px;
}

.tagsViewIcons img {
  height: 24px;
  width: 24px;
  vertical-align: unset;
}

.editInput {
  width: 100%;
  display: flex;
}

.metaDetails {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--disabledTextColor);
  font-size: var(--normalFontSm);
  text-transform: capitalize;
  white-space: nowrap;
}

.notesList table tr td {
  vertical-align: top;
}

.listNotsright {
  display: flex;
  justify-content: space-between;
}
