.highlightOption {
  color: var(--cardTitle);
}

.multiSelectContainer li {
  background: unset;
  color: var(--cardTitle);
  outline: unset;
}

.multiSelectContainer li:hover {
  background: var(--lightGrey);
  color: var(--cardTitle);
  outline: unset;
}

.multiSelectContainer {
  border: unset;
}

/* .multiSelectContainer ul {
   max-height: 100px; 
} */

.searchWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.searchWrapper input {
  margin-top: unset;
  font-size: var(--normalFontMd);
}

.chip {
  margin: unset;
}
