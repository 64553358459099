.dropOuterView {
  margin: 24px 0 16px 0;
}

.dropView {
  min-height: 143px;
  width: 100%;
  border: 1px dashed var(--inputBorderColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 28px;
}

.dropOuterView .labelText {
  font-size: var(--normalFontMd);
  margin: 4px;
  color: var(--lightBlack);
}

.dropOuterView .labelText span {
  color: var(--errorColor);
}

.dropOuterView .dropIcon {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.dropOuterView .dropIcon .uploadText {
  color: var(--cardTitle);
  font-weight: var(--semiBoldWeight);
}

.dropOuterView .dragText {
  color: var(--lightBlack);
  font-size: var(--normalFontMd);
}

.dropOuterView .dragText span {
  color: var(--themeDarkColor);
  text-decoration: underline;
  cursor: pointer;
}

.sc-bcXHqe {
  width: 100%;
}

.dropViewUploadedImage {
  height: 100%;
  /* max-height: 300px; */
  width: 45%;
}

.dragDropButtons {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 15px 0 0 0;
  position: relative;
  z-index: 999;
}

.articleRemoveIcon {
  color: var(--errorColor);
  border-radius: 10px;
  border: 1px solid var(--errorColor);
  background-color: unset;
  font-size: var(--normalFontMd);
  font-weight: var(--semiBoldWeight);
}

.articleRemoveIconNoBorder {
  color: var(--themeDarkColor);
  border: 1px solid transparent;
}

@media only screen and (max-width: 767px) {
  .dropViewUploadedImage {
    width: 100%;
  }
}
